export default {
  palette: {
    primary: {
      main: '#0fb9b9',
    },
    secondary: {
      main: '#ffffff',
    },
  },
}
